export const PetalumaScriptFont = {
    spacing: 50,
    glyphs: {
        ' ': {
            x_min: 0,
            x_max: 250,
            y_min: 0,
            y_max: 500,
            ha: 500,
            leftSideBearing: 0,
            advanceWidth: 250,
        },
        '0': {
            x_min: 33,
            x_max: 534,
            y_min: -13,
            y_max: 751,
            ha: 764,
            leftSideBearing: 33,
            advanceWidth: 570,
        },
        '1': {
            x_min: 48,
            x_max: 235,
            y_min: -17,
            y_max: 734,
            ha: 751,
            leftSideBearing: 48,
            advanceWidth: 286,
        },
        '2': {
            x_min: 56,
            x_max: 571,
            y_min: -19,
            y_max: 741,
            ha: 760,
            leftSideBearing: 56,
            advanceWidth: 626,
        },
        '3': {
            x_min: 50,
            x_max: 528,
            y_min: -7,
            y_max: 731,
            ha: 738,
            leftSideBearing: 50,
            advanceWidth: 589,
        },
        '4': {
            x_min: 38,
            x_max: 564,
            y_min: -13,
            y_max: 743,
            ha: 756,
            leftSideBearing: 38,
            advanceWidth: 614,
        },
        '5': {
            x_min: 42,
            x_max: 601,
            y_min: -34,
            y_max: 743,
            ha: 777,
            leftSideBearing: 42,
            advanceWidth: 648,
        },
        '6': {
            x_min: 47,
            x_max: 606,
            y_min: -16,
            y_max: 743,
            ha: 759,
            leftSideBearing: 47,
            advanceWidth: 667,
        },
        '7': {
            x_min: 47,
            x_max: 567,
            y_min: 5,
            y_max: 739,
            ha: 734,
            leftSideBearing: 0,
            advanceWidth: 615,
        },
        '8': {
            x_min: 40,
            x_max: 541,
            y_min: -15,
            y_max: 752,
            ha: 767,
            leftSideBearing: 40,
            advanceWidth: 585,
        },
        '9': {
            x_min: 40,
            x_max: 537,
            y_min: -52,
            y_max: 736,
            ha: 788,
            leftSideBearing: 40,
            advanceWidth: 592,
        },
        '!': {
            x_min: 49,
            x_max: 180,
            y_min: -7,
            y_max: 761,
            ha: 768,
            leftSideBearing: 49,
            advanceWidth: 230,
        },
        '"': {
            x_min: 39,
            x_max: 304,
            y_min: 596,
            y_max: 804,
            ha: 208,
            leftSideBearing: 39,
            advanceWidth: 353,
        },
        '#': {
            x_min: 33,
            x_max: 599,
            y_min: -8,
            y_max: 765,
            ha: 773,
            leftSideBearing: 33,
            advanceWidth: 648,
        },
        $: {
            x_min: 53,
            x_max: 495,
            y_min: -111,
            y_max: 823,
            ha: 934,
            leftSideBearing: 53,
            advanceWidth: 560,
        },
        '%': {
            x_min: 35,
            x_max: 656,
            y_min: -128,
            y_max: 808,
            ha: 936,
            leftSideBearing: 35,
            advanceWidth: 711,
        },
        '&': {
            x_min: 53,
            x_max: 441,
            y_min: -3,
            y_max: 753,
            ha: 756,
            leftSideBearing: 53,
            advanceWidth: 500,
        },
        "'": {
            x_min: 43,
            x_max: 161,
            y_min: 590,
            y_max: 811,
            ha: 221,
            leftSideBearing: 43,
            advanceWidth: 217,
        },
        '(': {
            x_min: 41,
            x_max: 311,
            y_min: -36,
            y_max: 845,
            ha: 881,
            leftSideBearing: 41,
            advanceWidth: 308,
        },
        ')': {
            x_min: -8,
            x_max: 257,
            y_min: -21,
            y_max: 852,
            ha: 873,
            leftSideBearing: -8,
            advanceWidth: 280,
        },
        '*': {
            x_min: 59,
            x_max: 539,
            y_min: 175,
            y_max: 589,
            ha: 414,
            leftSideBearing: 59,
            advanceWidth: 601,
        },
        '+': {
            x_min: 33,
            x_max: 361,
            y_min: 180,
            y_max: 587,
            ha: 407,
            leftSideBearing: 33,
            advanceWidth: 400,
        },
        ',': {
            x_min: 15,
            x_max: 176,
            y_min: -129,
            y_max: 92,
            ha: 221,
            leftSideBearing: 15,
            advanceWidth: 205,
        },
        '-': {
            x_min: 40,
            x_max: 380,
            y_min: 317,
            y_max: 452,
            ha: 135,
            leftSideBearing: 40,
            advanceWidth: 422,
        },
        '.': {
            x_min: 48,
            x_max: 185,
            y_min: -56,
            y_max: 84,
            ha: 140,
            leftSideBearing: 48,
            advanceWidth: 227,
        },
        '/': {
            x_min: -58,
            x_max: 654,
            y_min: -122,
            y_max: 844,
            ha: 966,
            leftSideBearing: -58,
            advanceWidth: 626,
        },
        ':': {
            x_min: 65,
            x_max: 225,
            y_min: 97,
            y_max: 536,
            ha: 439,
            leftSideBearing: 65,
            advanceWidth: 302,
        },
        ';': {
            x_min: 13,
            x_max: 295,
            y_min: -139,
            y_max: 536,
            ha: 675,
            leftSideBearing: 13,
            advanceWidth: 334,
        },
        '<': {
            x_min: 28,
            x_max: 438,
            y_min: -1,
            y_max: 607,
            ha: 608,
            leftSideBearing: 28,
            advanceWidth: 475,
        },
        '=': {
            x_min: 40,
            x_max: 383,
            y_min: 199.9598640852289,
            y_max: 541,
            ha: 341.0401359147711,
            leftSideBearing: 40,
            advanceWidth: 422,
        },
        '>': {
            x_min: 35,
            x_max: 421,
            y_min: 28,
            y_max: 632,
            ha: 604,
            leftSideBearing: 35,
            advanceWidth: 466,
        },
        '?': {
            x_min: 45,
            x_max: 548,
            y_min: -17,
            y_max: 767,
            ha: 784,
            leftSideBearing: 45,
            advanceWidth: 592,
        },
        '@': {
            x_min: 51,
            x_max: 730,
            y_min: -78,
            y_max: 753,
            ha: 831,
            leftSideBearing: 51,
            advanceWidth: 781,
        },
        A: {
            x_min: 37,
            x_max: 554,
            y_min: -4,
            y_max: 746,
            ha: 750,
            leftSideBearing: 37,
            advanceWidth: 617,
        },
        B: {
            x_min: 37,
            x_max: 532,
            y_min: 3,
            y_max: 783,
            ha: 780,
            leftSideBearing: 37,
            advanceWidth: 579,
        },
        C: {
            x_min: 37,
            x_max: 583,
            y_min: -3,
            y_max: 775,
            ha: 778,
            leftSideBearing: 37,
            advanceWidth: 623,
        },
        D: {
            x_min: 50,
            x_max: 530,
            y_min: -15,
            y_max: 749,
            ha: 764,
            leftSideBearing: 50,
            advanceWidth: 579,
        },
        E: {
            x_min: 45,
            x_max: 531,
            y_min: -1,
            y_max: 743,
            ha: 744,
            leftSideBearing: 45,
            advanceWidth: 585,
        },
        F: {
            x_min: 45,
            x_max: 459,
            y_min: 23,
            y_max: 727,
            ha: 704,
            leftSideBearing: 45,
            advanceWidth: 510,
        },
        G: {
            x_min: 31,
            x_max: 577,
            y_min: -8,
            y_max: 733,
            ha: 741,
            leftSideBearing: 31,
            advanceWidth: 611,
        },
        H: {
            x_min: 37,
            x_max: 493,
            y_min: -22,
            y_max: 758,
            ha: 780,
            leftSideBearing: 37,
            advanceWidth: 535,
        },
        I: {
            x_min: 47,
            x_max: 501,
            y_min: -3,
            y_max: 731,
            ha: 734,
            leftSideBearing: 47,
            advanceWidth: 541,
        },
        J: {
            x_min: 33,
            x_max: 531,
            y_min: -23,
            y_max: 725,
            ha: 748,
            leftSideBearing: 33,
            advanceWidth: 573,
        },
        K: {
            x_min: 43,
            x_max: 505,
            y_min: -10,
            y_max: 740,
            ha: 750,
            leftSideBearing: 43,
            advanceWidth: 560,
        },
        L: {
            x_min: 49,
            x_max: 457,
            y_min: -2,
            y_max: 746,
            ha: 748,
            leftSideBearing: 49,
            advanceWidth: 510,
        },
        M: {
            x_min: 35,
            x_max: 699,
            y_min: 1,
            y_max: 744,
            ha: 743,
            leftSideBearing: 35,
            advanceWidth: 743,
        },
        N: {
            x_min: 34,
            x_max: 533,
            y_min: -17,
            y_max: 761,
            ha: 778,
            leftSideBearing: 34,
            advanceWidth: 579,
        },
        O: {
            x_min: 41,
            x_max: 608,
            y_min: -5,
            y_max: 735,
            ha: 740,
            leftSideBearing: 41,
            advanceWidth: 667,
        },
        P: {
            x_min: 53,
            x_max: 451,
            y_min: -18,
            y_max: 735,
            ha: 753,
            leftSideBearing: 53,
            advanceWidth: 497,
        },
        Q: {
            x_min: 40,
            x_max: 599,
            y_min: -67,
            y_max: 744,
            ha: 811,
            leftSideBearing: 40,
            advanceWidth: 648,
        },
        R: {
            x_min: 39,
            x_max: 487,
            y_min: 16,
            y_max: 735,
            ha: 719,
            leftSideBearing: 39,
            advanceWidth: 535,
        },
        S: {
            x_min: 35,
            x_max: 552,
            y_min: -47,
            y_max: 708,
            ha: 755,
            leftSideBearing: 35,
            advanceWidth: 604,
        },
        T: {
            x_min: 26,
            x_max: 656,
            y_min: -13,
            y_max: 718,
            ha: 731,
            leftSideBearing: 26,
            advanceWidth: 705,
        },
        U: {
            x_min: 41,
            x_max: 518,
            y_min: -20,
            y_max: 748,
            ha: 768,
            leftSideBearing: 41,
            advanceWidth: 567,
        },
        V: {
            x_min: 47,
            x_max: 509,
            y_min: -26,
            y_max: 744,
            ha: 770,
            leftSideBearing: 47,
            advanceWidth: 567,
        },
        W: {
            x_min: 44,
            x_max: 789,
            y_min: -35,
            y_max: 720,
            ha: 755,
            leftSideBearing: 44,
            advanceWidth: 833,
        },
        X: {
            x_min: 63,
            x_max: 635,
            y_min: -10,
            y_max: 745,
            ha: 755,
            leftSideBearing: 63,
            advanceWidth: 680,
        },
        Y: {
            x_min: 43,
            x_max: 503,
            y_min: -21,
            y_max: 734,
            ha: 755,
            leftSideBearing: 43,
            advanceWidth: 541,
        },
        Z: {
            x_min: 42,
            x_max: 584,
            y_min: -10,
            y_max: 739,
            ha: 749,
            leftSideBearing: 42,
            advanceWidth: 629,
        },
        '[': {
            x_min: 46,
            x_max: 346,
            y_min: -150,
            y_max: 884,
            ha: 1034,
            leftSideBearing: 46,
            advanceWidth: 291,
        },
        '\\': {
            x_min: 20,
            x_max: 616,
            y_min: -100,
            y_max: 797,
            ha: 897,
            leftSideBearing: 20,
            advanceWidth: 645,
        },
        ']': {
            x_min: -76,
            x_max: 226,
            y_min: -150,
            y_max: 881,
            ha: 1031,
            leftSideBearing: -76,
            advanceWidth: 297,
        },
        '^': {
            x_min: 43,
            x_max: 437,
            y_min: 517,
            y_max: 812,
            ha: 295,
            leftSideBearing: 43,
            advanceWidth: 478,
        },
        _: {
            x_min: 29,
            x_max: 563,
            y_min: -110,
            y_max: -1,
            ha: 109,
            leftSideBearing: 29,
            advanceWidth: 598,
        },
        '`': {
            x_min: 54,
            x_max: 321,
            y_min: 540,
            y_max: 747,
            ha: 207,
            leftSideBearing: 54,
            advanceWidth: 368,
        },
        a: {
            x_min: 20,
            x_max: 447,
            y_min: -17,
            y_max: 495,
            ha: 512,
            leftSideBearing: 20,
            advanceWidth: 494,
        },
        b: {
            x_min: 37,
            x_max: 466,
            y_min: -27,
            y_max: 770,
            ha: 797,
            leftSideBearing: 37,
            advanceWidth: 510,
        },
        c: {
            x_min: 35,
            x_max: 456,
            y_min: -9,
            y_max: 507,
            ha: 516,
            leftSideBearing: 35,
            advanceWidth: 460,
        },
        d: {
            x_min: 45,
            x_max: 520,
            y_min: -15,
            y_max: 764,
            ha: 779,
            leftSideBearing: 45,
            advanceWidth: 560,
        },
        e: {
            x_min: 33,
            x_max: 370,
            y_min: -5,
            y_max: 501,
            ha: 506,
            leftSideBearing: 33,
            advanceWidth: 397,
        },
        f: {
            x_min: 35,
            x_max: 411,
            y_min: -13,
            y_max: 695,
            ha: 708,
            leftSideBearing: 35,
            advanceWidth: 453,
        },
        g: {
            x_min: 33,
            x_max: 551,
            y_min: -257,
            y_max: 505,
            ha: 762,
            leftSideBearing: 33,
            advanceWidth: 611,
        },
        h: {
            x_min: 32,
            x_max: 458,
            y_min: -29,
            y_max: 743,
            ha: 772,
            leftSideBearing: 32,
            advanceWidth: 491,
        },
        i: {
            x_min: 45,
            x_max: 167,
            y_min: -13,
            y_max: 631,
            ha: 644,
            leftSideBearing: 45,
            advanceWidth: 220,
        },
        j: {
            x_min: -127,
            x_max: 261,
            y_min: -231,
            y_max: 601,
            ha: 832,
            leftSideBearing: -127,
            advanceWidth: 308,
        },
        k: {
            x_min: 39,
            x_max: 443,
            y_min: -3,
            y_max: 700,
            ha: 703,
            leftSideBearing: 39,
            advanceWidth: 497,
        },
        l: {
            x_min: 58,
            x_max: 168,
            y_min: -8,
            y_max: 686,
            ha: 694,
            leftSideBearing: 58,
            advanceWidth: 227,
        },
        m: {
            x_min: 39,
            x_max: 688,
            y_min: -14,
            y_max: 501,
            ha: 515,
            leftSideBearing: 39,
            advanceWidth: 718,
        },
        n: {
            x_min: 45,
            x_max: 491,
            y_min: -32,
            y_max: 512,
            ha: 544,
            leftSideBearing: 45,
            advanceWidth: 541,
        },
        o: {
            x_min: 41,
            x_max: 424,
            y_min: -12,
            y_max: 493,
            ha: 505,
            leftSideBearing: 41,
            advanceWidth: 447,
        },
        p: {
            x_min: 25,
            x_max: 529,
            y_min: -260,
            y_max: 500,
            ha: 760,
            leftSideBearing: 25,
            advanceWidth: 573,
        },
        q: {
            x_min: 53,
            x_max: 603,
            y_min: -235,
            y_max: 514,
            ha: 749,
            leftSideBearing: 53,
            advanceWidth: 623,
        },
        r: {
            x_min: 32,
            x_max: 407,
            y_min: -7,
            y_max: 492,
            ha: 499,
            leftSideBearing: 32,
            advanceWidth: 460,
        },
        s: {
            x_min: 49,
            x_max: 416,
            y_min: -12,
            y_max: 519,
            ha: 531,
            leftSideBearing: 49,
            advanceWidth: 460,
        },
        t: {
            x_min: 32,
            x_max: 442,
            y_min: -5,
            y_max: 684,
            ha: 689,
            leftSideBearing: 32,
            advanceWidth: 469,
        },
        u: {
            x_min: 41,
            x_max: 487,
            y_min: -5,
            y_max: 507,
            ha: 512,
            leftSideBearing: 41,
            advanceWidth: 510,
        },
        v: {
            x_min: 16,
            x_max: 441,
            y_min: -20,
            y_max: 542,
            ha: 562,
            leftSideBearing: 16,
            advanceWidth: 456,
        },
        w: {
            x_min: 39,
            x_max: 639,
            y_min: -22,
            y_max: 505,
            ha: 527,
            leftSideBearing: 39,
            advanceWidth: 661,
        },
        x: {
            x_min: 15,
            x_max: 505,
            y_min: -39,
            y_max: 539,
            ha: 578,
            leftSideBearing: 15,
            advanceWidth: 541,
        },
        y: {
            x_min: -98,
            x_max: 501,
            y_min: -242,
            y_max: 511,
            ha: 753,
            leftSideBearing: -98,
            advanceWidth: 548,
        },
        z: {
            x_min: 27,
            x_max: 479,
            y_min: -3,
            y_max: 494,
            ha: 497,
            leftSideBearing: 27,
            advanceWidth: 494,
        },
        '{': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 308,
        },
        '|': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 308,
        },
        '}': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 308,
        },
        '~': {
            x_min: 0,
            x_max: 0,
            y_min: 0,
            y_max: 0,
            ha: 0,
            leftSideBearing: 0,
            advanceWidth: 308,
        },
    },
    fontFamily: 'PetalumaScript',
    resolution: 1000,
    generatedOn: '2020-06-14T18:33:25.407Z',
};
