export const RobotoSlabFont = {
    spacing: 50,
    glyphs: {
        ' ': {
            x_min: 0,
            x_max: 509,
            y_min: 0,
            y_max: 2000,
            ha: 2000,
            leftSideBearing: 0,
            advanceWidth: 509,
        },
        '0': {
            x_min: 121,
            x_max: 1048,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 121,
            advanceWidth: 1168,
        },
        '1': {
            x_min: 117,
            x_max: 767,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 117,
            advanceWidth: 844,
        },
        '2': {
            x_min: 75.8474576645637,
            x_max: 1040,
            y_min: 0,
            y_max: 1477,
            ha: 1477,
            leftSideBearing: 75,
            advanceWidth: 1131,
        },
        '3': {
            x_min: 74.85365853658536,
            x_max: 994,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 74,
            advanceWidth: 1106,
        },
        '4': {
            x_min: 63,
            x_max: 1114,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 63,
            advanceWidth: 1187,
        },
        '5': {
            x_min: 96.8448275474953,
            x_max: 982,
            y_min: -21,
            y_max: 1456,
            ha: 1477,
            leftSideBearing: 96,
            advanceWidth: 1081,
        },
        '6': {
            x_min: 121,
            x_max: 1067,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 121,
            advanceWidth: 1144,
        },
        '7': {
            x_min: 76,
            x_max: 1060,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: -30,
            advanceWidth: 1128,
        },
        '8': {
            x_min: 92,
            x_max: 1039,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 92,
            advanceWidth: 1131,
        },
        '9': {
            x_min: 84,
            x_max: 1026,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 84,
            advanceWidth: 1154,
        },
        '!': {
            x_min: 143,
            x_max: 340,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 143,
            advanceWidth: 483,
        },
        '"': {
            x_min: 166,
            x_max: 634,
            y_min: 1055,
            y_max: 1560,
            ha: 505,
            leftSideBearing: 166,
            advanceWidth: 770,
        },
        '#': {
            x_min: 63,
            x_max: 1179,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 63,
            advanceWidth: 1246,
        },
        $: {
            x_min: 81.92156915727656,
            x_max: 1012,
            y_min: -208,
            y_max: 1693,
            ha: 1901,
            leftSideBearing: 81,
            advanceWidth: 1110,
        },
        '%': {
            x_min: 77,
            x_max: 1384,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 77,
            advanceWidth: 1460,
        },
        '&': {
            x_min: 74,
            x_max: 1242,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 74,
            advanceWidth: 1289,
        },
        "'": {
            x_min: 166,
            x_max: 324,
            y_min: 1055,
            y_max: 1560,
            ha: 505,
            leftSideBearing: 166,
            advanceWidth: 460,
        },
        '(': {
            x_min: 114,
            x_max: 650,
            y_min: -463,
            y_max: 1636,
            ha: 2099,
            leftSideBearing: 114,
            advanceWidth: 662,
        },
        ')': {
            x_min: 11,
            x_max: 547,
            y_min: -463,
            y_max: 1636,
            ha: 2099,
            leftSideBearing: 11,
            advanceWidth: 654,
        },
        '*': {
            x_min: 66,
            x_max: 893,
            y_min: 607,
            y_max: 1456,
            ha: 849,
            leftSideBearing: 66,
            advanceWidth: 958,
        },
        '+': {
            x_min: 73,
            x_max: 1071,
            y_min: 146,
            y_max: 1206,
            ha: 1060,
            leftSideBearing: 73,
            advanceWidth: 1145,
        },
        ',': {
            x_min: 23,
            x_max: 315,
            y_min: -311,
            y_max: 221,
            ha: 532,
            leftSideBearing: 23,
            advanceWidth: 404,
        },
        '-': {
            x_min: 154,
            x_max: 643,
            y_min: 538,
            y_max: 692,
            ha: 154,
            leftSideBearing: 154,
            advanceWidth: 797,
        },
        '.': {
            x_min: 145,
            x_max: 342,
            y_min: 0,
            y_max: 202,
            ha: 202,
            leftSideBearing: 145,
            advanceWidth: 493,
        },
        '/': {
            x_min: 0,
            x_max: 774,
            y_min: -125,
            y_max: 1456,
            ha: 1581,
            leftSideBearing: 0,
            advanceWidth: 824,
        },
        ':': {
            x_min: 145,
            x_max: 342,
            y_min: 0,
            y_max: 1082,
            ha: 1082,
            leftSideBearing: 145,
            advanceWidth: 419,
        },
        ';': {
            x_min: 53,
            x_max: 345,
            y_min: -311,
            y_max: 1082,
            ha: 1393,
            leftSideBearing: 53,
            advanceWidth: 424,
        },
        '<': {
            x_min: 76,
            x_max: 892,
            y_min: 107,
            y_max: 1013,
            ha: 906,
            leftSideBearing: 76,
            advanceWidth: 1017,
        },
        '=': {
            x_min: 147,
            x_max: 981,
            y_min: 407,
            y_max: 987,
            ha: 580,
            leftSideBearing: 147,
            advanceWidth: 1127,
        },
        '>': {
            x_min: 125,
            x_max: 980,
            y_min: 87,
            y_max: 993,
            ha: 906,
            leftSideBearing: 125,
            advanceWidth: 1060,
        },
        '?': {
            x_min: 65.92660602419838,
            x_max: 890,
            y_min: 0,
            y_max: 1477,
            ha: 1477,
            leftSideBearing: 65,
            advanceWidth: 953,
        },
        '@': {
            x_min: 84.3592218305664,
            x_max: 1705.2346925277395,
            y_min: -453,
            y_max: 1431,
            ha: 1884,
            leftSideBearing: 84,
            advanceWidth: 1821,
        },
        A: {
            x_min: 70,
            x_max: 1463,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 70,
            advanceWidth: 1533,
        },
        B: {
            x_min: 81,
            x_max: 1253,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1322,
        },
        C: {
            x_min: 80,
            x_max: 1174,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 80,
            advanceWidth: 1290,
        },
        D: {
            x_min: 81,
            x_max: 1291,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1356,
        },
        E: {
            x_min: 81,
            x_max: 1190,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1306,
        },
        F: {
            x_min: 81,
            x_max: 1176,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1256,
        },
        G: {
            x_min: 80,
            x_max: 1226,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 80,
            advanceWidth: 1346,
        },
        H: {
            x_min: 81,
            x_max: 1497,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1578,
        },
        I: {
            x_min: 81,
            x_max: 604,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 685,
        },
        J: {
            x_min: 60,
            x_max: 1100,
            y_min: -21,
            y_max: 1456,
            ha: 1477,
            leftSideBearing: 60,
            advanceWidth: 1169,
        },
        K: {
            x_min: 81,
            x_max: 1427,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1501,
        },
        L: {
            x_min: 81,
            x_max: 1130,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1210,
        },
        M: {
            x_min: 94,
            x_max: 1888,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 94,
            advanceWidth: 1982,
        },
        N: {
            x_min: 83,
            x_max: 1509,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 83,
            advanceWidth: 1592,
        },
        O: {
            x_min: 70,
            x_max: 1281,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 70,
            advanceWidth: 1351,
        },
        P: {
            x_min: 81,
            x_max: 1242,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 81,
            advanceWidth: 1297,
        },
        Q: {
            x_min: 70,
            x_max: 1348,
            y_min: -245,
            y_max: 1477,
            ha: 1722,
            leftSideBearing: 70,
            advanceWidth: 1376,
        },
        R: {
            x_min: 81,
            x_max: 1285,
            y_min: 0,
            y_max: 1455,
            ha: 1455,
            leftSideBearing: 81,
            advanceWidth: 1385,
        },
        S: {
            x_min: 115,
            x_max: 1116,
            y_min: -21,
            y_max: 1477,
            ha: 1498,
            leftSideBearing: 115,
            advanceWidth: 1206,
        },
        T: {
            x_min: 96,
            x_max: 1306,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 96,
            advanceWidth: 1406,
        },
        U: {
            x_min: 70,
            x_max: 1452,
            y_min: -21,
            y_max: 1456,
            ha: 1477,
            leftSideBearing: 70,
            advanceWidth: 1522,
        },
        V: {
            x_min: 65,
            x_max: 1443,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 65,
            advanceWidth: 1508,
        },
        W: {
            x_min: 72,
            x_max: 2057,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 72,
            advanceWidth: 2129,
        },
        X: {
            x_min: 80,
            x_max: 1449,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 80,
            advanceWidth: 1529,
        },
        Y: {
            x_min: 65,
            x_max: 1396,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 65,
            advanceWidth: 1461,
        },
        Z: {
            x_min: 90,
            x_max: 1107,
            y_min: 0,
            y_max: 1456,
            ha: 1456,
            leftSideBearing: 90,
            advanceWidth: 1197,
        },
        '[': {
            x_min: 157,
            x_max: 541,
            y_min: -312,
            y_max: 1664,
            ha: 1976,
            leftSideBearing: 157,
            advanceWidth: 573,
        },
        '\\': {
            x_min: 36,
            x_max: 830,
            y_min: -125,
            y_max: 1456,
            ha: 1581,
            leftSideBearing: 36,
            advanceWidth: 841,
        },
        ']': {
            x_min: 23,
            x_max: 408,
            y_min: -312,
            y_max: 1664,
            ha: 1976,
            leftSideBearing: 23,
            advanceWidth: 552,
        },
        '^': {
            x_min: 71,
            x_max: 802,
            y_min: 729,
            y_max: 1456,
            ha: 727,
            leftSideBearing: 71,
            advanceWidth: 864,
        },
        _: {
            x_min: 119,
            x_max: 1041,
            y_min: -154,
            y_max: 0,
            ha: 154,
            leftSideBearing: 119,
            advanceWidth: 1168,
        },
        '`': {
            x_min: 40,
            x_max: 456,
            y_min: 1288,
            y_max: 1560,
            ha: 272,
            leftSideBearing: 40,
            advanceWidth: 496,
        },
        a: {
            x_min: 79,
            x_max: 1053,
            y_min: -21,
            y_max: 1102,
            ha: 1123,
            leftSideBearing: 79,
            advanceWidth: 1132,
        },
        b: {
            x_min: -16,
            x_max: 1071,
            y_min: -21,
            y_max: 1560,
            ha: 1581,
            leftSideBearing: -16,
            advanceWidth: 1145,
        },
        c: {
            x_min: 78,
            x_max: 988,
            y_min: -21,
            y_max: 1102,
            ha: 1123,
            leftSideBearing: 78,
            advanceWidth: 1067,
        },
        d: {
            x_min: 78,
            x_max: 1156,
            y_min: -21,
            y_max: 1560,
            ha: 1581,
            leftSideBearing: 78,
            advanceWidth: 1218,
        },
        e: {
            x_min: 74,
            x_max: 980,
            y_min: -21,
            y_max: 1102,
            ha: 1123,
            leftSideBearing: 74,
            advanceWidth: 1059,
        },
        f: {
            x_min: 64,
            x_max: 731,
            y_min: 0,
            y_max: 1581,
            ha: 1581,
            leftSideBearing: 64,
            advanceWidth: 725,
        },
        g: {
            x_min: 78,
            x_max: 1133,
            y_min: -437,
            y_max: 1102,
            ha: 1539,
            leftSideBearing: 78,
            advanceWidth: 1157,
        },
        h: {
            x_min: 49,
            x_max: 1255,
            y_min: 0,
            y_max: 1560,
            ha: 1560,
            leftSideBearing: 49,
            advanceWidth: 1315,
        },
        i: {
            x_min: 67,
            x_max: 590,
            y_min: 0,
            y_max: 1560,
            ha: 1560,
            leftSideBearing: 67,
            advanceWidth: 652,
        },
        j: {
            x_min: -56,
            x_max: 381,
            y_min: -437,
            y_max: 1560,
            ha: 1997,
            leftSideBearing: -56,
            advanceWidth: 525,
        },
        k: {
            x_min: 64,
            x_max: 1248,
            y_min: 0,
            y_max: 1560,
            ha: 1560,
            leftSideBearing: 64,
            advanceWidth: 1287,
        },
        l: {
            x_min: 64,
            x_max: 587,
            y_min: 0,
            y_max: 1560,
            ha: 1560,
            leftSideBearing: 64,
            advanceWidth: 653,
        },
        m: {
            x_min: 74,
            x_max: 1905,
            y_min: 0,
            y_max: 1102,
            ha: 1102,
            leftSideBearing: 74,
            advanceWidth: 1977,
        },
        n: {
            x_min: 74,
            x_max: 1278,
            y_min: 0,
            y_max: 1102,
            ha: 1102,
            leftSideBearing: 74,
            advanceWidth: 1343,
        },
        o: {
            x_min: 78,
            x_max: 1046,
            y_min: -21,
            y_max: 1102,
            ha: 1123,
            leftSideBearing: 78,
            advanceWidth: 1124,
        },
        p: {
            x_min: 34,
            x_max: 1119,
            y_min: -416,
            y_max: 1102,
            ha: 1518,
            leftSideBearing: 34,
            advanceWidth: 1197,
        },
        q: {
            x_min: 78,
            x_max: 1135,
            y_min: -416,
            y_max: 1102,
            ha: 1518,
            leftSideBearing: 78,
            advanceWidth: 1139,
        },
        r: {
            x_min: 72,
            x_max: 808,
            y_min: 0,
            y_max: 1102,
            ha: 1102,
            leftSideBearing: 72,
            advanceWidth: 854,
        },
        s: {
            x_min: 104,
            x_max: 933,
            y_min: -21,
            y_max: 1102,
            ha: 1123,
            leftSideBearing: 104,
            advanceWidth: 1022,
        },
        t: {
            x_min: 24,
            x_max: 678,
            y_min: -17,
            y_max: 1343,
            ha: 1360,
            leftSideBearing: 24,
            advanceWidth: 730,
        },
        u: {
            x_min: 52,
            x_max: 1181,
            y_min: -21,
            y_max: 1082,
            ha: 1103,
            leftSideBearing: 52,
            advanceWidth: 1245,
        },
        v: {
            x_min: 26,
            x_max: 1176,
            y_min: 0,
            y_max: 1082,
            ha: 1082,
            leftSideBearing: 26,
            advanceWidth: 1206,
        },
        w: {
            x_min: 52,
            x_max: 1777,
            y_min: 0,
            y_max: 1082,
            ha: 1082,
            leftSideBearing: 52,
            advanceWidth: 1823,
        },
        x: {
            x_min: 42,
            x_max: 1163,
            y_min: 0,
            y_max: 1082,
            ha: 1082,
            leftSideBearing: 42,
            advanceWidth: 1203,
        },
        y: {
            x_min: 24,
            x_max: 1172,
            y_min: -437,
            y_max: 1082,
            ha: 1519,
            leftSideBearing: 24,
            advanceWidth: 1192,
        },
        z: {
            x_min: 88,
            x_max: 960,
            y_min: 0,
            y_max: 1082,
            ha: 1082,
            leftSideBearing: 88,
            advanceWidth: 1032,
        },
        '{': {
            x_min: 63,
            x_max: 671,
            y_min: -364,
            y_max: 1597,
            ha: 1961,
            leftSideBearing: 63,
            advanceWidth: 701,
        },
        '|': {
            x_min: 150,
            x_max: 307,
            y_min: -270,
            y_max: 1456,
            ha: 1726,
            leftSideBearing: 150,
            advanceWidth: 451,
        },
        '}': {
            x_min: 27,
            x_max: 635,
            y_min: -364,
            y_max: 1597,
            ha: 1961,
            leftSideBearing: 27,
            advanceWidth: 701,
        },
        '~': {
            x_min: 121,
            x_max: 1257,
            y_min: 401,
            y_max: 803,
            ha: 402,
            leftSideBearing: 121,
            advanceWidth: 1386,
        },
    },
    fontFamily: 'Roboto Slab',
    resolution: 2048,
    generatedOn: '2020-06-15T19:12:58.120Z',
};
